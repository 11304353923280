import { useMemo } from "react";
import { useQuery } from "react-query";
import { api } from "../api";
import { Trip } from "../types/trip.types";

export const useGetAllUserTrips = () => {
  const query = useQuery(["trips"], () => api.getAllTrips(), {
    refetchOnMount: true,
  });
  const tripData = useMemo(() => {
    if (query.data) {
      const mapped = query.data.map((trip) => {
        const returnTrip: Trip = {
          tripId: trip?.id,
          rainFall: trip?.rainFall,
          photoUrl: trip?.photoUrl,
          accessories: {
            beanies: trip?.beanies,
            hats: trip?.hats,
            sunglasses: trip?.sunglasses,
            umbrella: trip?.umbrella,
          },
          clothing: {
            heavyCoats: trip?.heavyCoats,
            jackets: trip?.jackets,
            rainJackets: trip?.rainJackets,
            pants: trip?.pants,
            shorts: trip?.shorts,
            socks: trip?.socks,
            sweaters: trip?.sweaters,
            tShirts: trip?.tShirts,
            underwear: trip?.underwear,
          },
          destination: trip?.destination,
          startDate: trip?.startDate ? new Date(trip?.startDate) : null,
          endDate: trip?.endDate ? new Date(trip?.endDate) : null,
          temperature: {
            averageHigh: trip?.maxTemp,
            averageLow: trip?.minTemp,
          },
        };
        return returnTrip;
      });
      return mapped;
    }
    return null;
  }, [query.data]);

  return {
    ...query,
    data: tripData,
  };
};
