interface IconProps {
  icon: string;
  className?: string;
  height?: number;
  width?: number;
}

const SvgIcon = ({ className, icon, height, width }: IconProps) => {
  return (
    <div style={{ height, width }} className={className}>
      <img src={icon} alt={"loading"} />
    </div>
  );
};

export default SvgIcon;
