import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { LoadingSpinner } from "../../components/LoadingSpinner";
import { useGetPreferences } from "../../hooks/useGetPreferences";
import { useIncrementCounter } from "../../hooks/useIncrementCounter";
import { useSaveUserPreferences } from "../../hooks/useSaveUserPreferences";
import { useUpdateUserPreferences } from "../../hooks/useUpdateUserPreferences";
import { CheckBox } from "./CheckBox";
import InputField from "./InputField";
import styles from "./Preferences.module.css";

export enum InputType {
  number = "number",
  string = "string",
}

export interface UserPreferences {
  heavyCoatCount: number;
  jacketCount: number;
  pantsCount: number;
  shortsCount: number;
  sweatersCount: number;
  shirtsCount: number;
  socksCount: number;
  underwearCount: number;
  userId?: string;
  winterHats: boolean;
  likesHats: boolean;
  likesSunglasses: boolean;
  ownsRainJacket: boolean;
  umbrella: boolean;
  heavyCoats: number;
  jackets: number;
  pants: number;
  shorts: number;
}

export const USER_PREFS: UserPreferences = {
  heavyCoatCount: 1,
  jacketCount: 1,
  pantsCount: 2,
  shortsCount: 4,
  sweatersCount: 1,
  shirtsCount: 4,
  socksCount: 7,
  underwearCount: 7,
  winterHats: true,
  likesHats: true,
  likesSunglasses: true,
  ownsRainJacket: true,
  umbrella: true,
  heavyCoats: 32,
  jackets: 50,
  pants: 45,
  shorts: 45,
};

export const Preferences = () => {
  const updateCount = useIncrementCounter();
  const { data: preferences, isLoading } = useGetPreferences();
  const savePreferences = useSaveUserPreferences();
  const updatePreferences = useUpdateUserPreferences();
  const [heavyCoatCount, setHeavyCoatCount] = useState<number>(
    preferences?.heavyCoatCount || USER_PREFS.heavyCoatCount
  );
  const [jacketCount, setJacketCount] = useState<number>(
    preferences?.jacketCount || USER_PREFS.jacketCount
  );
  const [pantsCount, setPantsCount] = useState<number>(
    preferences?.pantsCount || USER_PREFS.pantsCount
  );
  const [shortsCount, setShortsCount] = useState<number>(
    USER_PREFS.shortsCount
  );
  const [sweatersCount, setSweatersCount] = useState<number>(
    USER_PREFS.sweatersCount
  );
  const [shirtsCount, setShirtsCount] = useState<number>(
    USER_PREFS.shirtsCount
  );
  const [socksCount, setSocksCount] = useState<number>(USER_PREFS.socksCount);
  const [underwearCount, setUnderwearCount] = useState<number>(
    USER_PREFS.underwearCount
  );
  const [winterHat, setWinterHat] = useState<boolean>(USER_PREFS.winterHats);
  const [hats, setHats] = useState<boolean>(USER_PREFS.likesHats);
  const [sunglasses, setSunglasses] = useState<boolean>(
    USER_PREFS.likesSunglasses
  );
  const [umbrella, setUmbrella] = useState<boolean>(USER_PREFS.umbrella);
  const [rainJacket, setRainJacket] = useState<boolean>(
    USER_PREFS.ownsRainJacket
  );
  const [heavyCoat, setHeavyCoat] = useState<number>(USER_PREFS.heavyCoats);
  const [jacket, setJacket] = useState<number>(USER_PREFS.jackets);
  const [pants, setPants] = useState<number>(USER_PREFS.pants);
  const [shorts, setShorts] = useState<number>(USER_PREFS.shorts);

  useEffect(() => {
    if (preferences) {
      // Counts
      setHeavyCoatCount(preferences.heavyCoatCount);
      setJacketCount(preferences.jacketCount);
      setShortsCount(preferences.shortsCount);
      setPantsCount(preferences.pantsCount);
      setSweatersCount(preferences.sweatersCount);
      setShirtsCount(preferences.shirtsCount);
      setSocksCount(preferences.socksCount);
      setUnderwearCount(preferences.underwearCount);
      setWinterHat(!!preferences.winterHats);
      setHats(!!preferences.likesHats);
      setSunglasses(!!preferences.likesSunglasses);
      setUmbrella(!!preferences.umbrella);
      setRainJacket(!!preferences.ownsRainJacket);

      // Temps
      setHeavyCoat(preferences.heavyCoats || USER_PREFS.heavyCoats);
      setJacket(preferences.jackets || USER_PREFS.jackets);
      setPants(preferences.pants || USER_PREFS.pants);
      setShorts(preferences.shorts || USER_PREFS.shorts);

      // Accessories
    }
  }, [preferences]);

  const navigate = useNavigate();

  const onHeavyCoatCountHandler = (value: string | number) => {
    if (value === 0) {
      setHeavyCoatCount(0);
    }
    if (!Number(value)) {
      return;
    } else {
      setHeavyCoatCount(Number(value));
    }
  };
  const onJacketCountHandler = (value: string | number) => {
    if (value === 0) {
      setJacketCount(0);
    }
    if (!Number(value)) {
      return;
    } else {
      setJacketCount(Number(value));
    }
  };
  const onPantsCountHandler = (value: string | number) => {
    if (value === 0) {
      setPantsCount(0);
    }
    if (!Number(value)) {
      return;
    } else {
      setPantsCount(Number(value));
    }
  };
  const onShortsCountHandler = (value: string | number) => {
    if (value === 0) {
      setShortsCount(0);
    }
    if (!Number(value)) {
      return;
    } else {
      setShortsCount(Number(value));
    }
  };
  const onSweatersCountHandler = (value: string | number) => {
    if (value === 0) {
      setSweatersCount(0);
    }
    if (!Number(value)) {
      return;
    } else {
      setSweatersCount(Number(value));
    }
  };
  const onShirtsCountHandler = (value: string | number) => {
    if (value === 0) {
      setShirtsCount(0);
    }
    if (!Number(value)) {
      return;
    } else {
      setShirtsCount(Number(value));
    }
  };
  const onSocksCountHandler = (value: string | number) => {
    if (value === 0) {
      setSocksCount(0);
    }
    if (!Number(value)) {
      return;
    } else {
      setSocksCount(Number(value));
    }
  };
  const onUnderwearCountHandler = (value: string | number) => {
    if (value === 0) {
      setUnderwearCount(0);
    }
    if (!Number(value)) {
      return;
    } else {
      setUnderwearCount(Number(value));
    }
  };

  const winterHatHandler = (value: boolean) => {
    setWinterHat(!value);
  };
  const onLikeHatsHandler = (value: boolean) => {
    setHats(!value);
  };
  const onLikeSunglassesHandler = (value: boolean) => {
    setSunglasses(!value);
  };
  const onOwnsRainJacketHandler = (value: boolean) => {
    setRainJacket(!value);
  };
  const onHeavyCoatHandler = (value: number | string) => {
    if (!Number(value)) {
      return;
    } else {
      setHeavyCoat(Number(value));
    }
  };
  const onJacketHandler = (value: number | string) => {
    if (!Number(value)) {
      return;
    } else {
      setJacket(Number(value));
    }
  };
  const onPantsHandler = (value: number | string) => {
    if (!Number(value)) {
      return;
    } else {
      setPants(Number(value));
    }
  };

  const onShortsHandler = (value: number | string) => {
    if (!Number(value)) {
      return;
    } else {
      setShorts(Number(value));
    }
  };

  const onUmbrellaHandler = (value: boolean) => {
    setUmbrella(!value);
  };

  if (isLoading) {
    return <LoadingSpinner />;
  }

  return (
    <div className={styles.Preferences}>
      <div className={styles.PreferencesContainer}>
        <div className={styles.PreferencesLabel}>Packing Count Preferences</div>
        <div className={styles.PreferencesHelper}>
          Recommended packing count (weather permitting).
        </div>
        <div className={styles.Form}>
          <InputField
            label={"Heavy Coats (total):"}
            type={InputType.string}
            onChange={onHeavyCoatCountHandler}
            value={heavyCoatCount}
          />
          <InputField
            label={"Jackets (total):"}
            type={InputType.string}
            onChange={onJacketCountHandler}
            value={jacketCount}
          />
          <InputField
            label={"Pants (per 7 days):"}
            type={InputType.string}
            onChange={onPantsCountHandler}
            value={pantsCount}
          />
          <InputField
            label={"Shorts (per 7 days):"}
            type={InputType.string}
            onChange={onShortsCountHandler}
            value={shortsCount}
          />
          <InputField
            label={"Sweaters (per 7 days):"}
            type={InputType.string}
            onChange={onSweatersCountHandler}
            value={sweatersCount}
          />
          <InputField
            label={"Shirts (per 7 days):"}
            type={InputType.string}
            onChange={onShirtsCountHandler}
            value={shirtsCount}
          />
          <InputField
            label={"Socks (per 7 days):"}
            type={InputType.string}
            onChange={onSocksCountHandler}
            value={socksCount}
          />
          <InputField
            label={"Underwear (per 7 days):"}
            type={InputType.string}
            onChange={onUnderwearCountHandler}
            value={underwearCount}
          />
        </div>
      </div>
      <div className={styles.PreferencesContainer}>
        <div className={styles.PreferencesLabel}>
          Temperature Preferences (F°)
        </div>
        <div className={styles.PreferencesHelper}>
          Temperature thresholds that define when clothes are generated.
        </div>
        <div className={styles.Form}>
          <InputField
            label={"Heavy Coats (F°):"}
            type={InputType.string}
            onChange={onHeavyCoatHandler}
            value={heavyCoat}
          />
          <InputField
            label={"Light Jackets (F°):"}
            type={InputType.string}
            onChange={onJacketHandler}
            value={jacket}
          />
          <InputField
            label={"Pants (F°):"}
            type={InputType.string}
            onChange={onPantsHandler}
            value={pants}
          />
          <InputField
            label={"Shorts (F°):"}
            type={InputType.string}
            onChange={onShortsHandler}
            value={shorts}
          />
        </div>
      </div>
      <div className={styles.PreferencesContainer}>
        <div className={styles.PreferencesLabel}>Accessory Preferences</div>
        <div className={styles.PreferencesHelper}>
          Trips will automatically be generated to include these unless
          unchecked.
        </div>
        <div className={styles.Form}>
          <div className={styles.Checkboxes}>
            <CheckBox
              label={"Hats"}
              value={hats}
              onChange={onLikeHatsHandler}
            />
            <CheckBox
              label={"Winter hats"}
              value={winterHat}
              onChange={winterHatHandler}
            />
            <CheckBox
              label={"Sunglasses"}
              value={sunglasses}
              onChange={onLikeSunglassesHandler}
            />
            <CheckBox
              label={"Umbrellas"}
              value={umbrella}
              onChange={onUmbrellaHandler}
            />
            <CheckBox
              label={"Rain Jackets"}
              value={rainJacket}
              onChange={onOwnsRainJacketHandler}
            />
          </div>
        </div>
      </div>
      <button
        className={styles.Button}
        onClick={() => {
          preferences
            ? updatePreferences({
                winterHats: winterHat,
                likesHats: hats,
                likesSunglasses: sunglasses,
                ownsRainJacket: rainJacket,
                umbrella: umbrella,
                heavyCoats: heavyCoat,
                jackets: jacket,
                pants: pants,
                shorts: shorts,
                heavyCoatCount: heavyCoatCount,
                jacketCount: jacketCount,
                pantsCount: pantsCount,
                shortsCount: shortsCount,
                sweatersCount: sweatersCount,
                shirtsCount: shirtsCount,
                socksCount: socksCount,
                underwearCount: underwearCount,
              })
            : savePreferences({
                winterHats: winterHat,
                likesHats: hats,
                likesSunglasses: sunglasses,
                ownsRainJacket: rainJacket,
                umbrella: umbrella,
                heavyCoats: heavyCoat,
                jackets: jacket,
                pants: pants,
                shorts: shorts,
                heavyCoatCount: heavyCoatCount,
                jacketCount: jacketCount,
                pantsCount: pantsCount,
                shortsCount: shortsCount,
                sweatersCount: sweatersCount,
                shirtsCount: shirtsCount,
                socksCount: socksCount,
                underwearCount: underwearCount,
              });
          navigate("/");
        }}
      >
        Update Preferences
      </button>
    </div>
  );
};
