import { useMutation } from "react-query";
import { api } from "../api";
export const useSignOut = () => {
  const signOut = useMutation(() => api.signOut(), {
    onSuccess: () => {
      window.location.reload();
    },
  });
  return {
    ...signOut,
    mutate: signOut.mutate,
  };
};
