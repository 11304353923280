import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import GoogleSignIn from "../../features/GoogleSignIn/GoogleSignIn";
import { useIsSignedIn } from "../../hooks/useIsSignedIn";
import { useSignIn } from "../../hooks/useSignIn";
import { useSignOut } from "../../hooks/useSignOut";
import { HamburgerMenu } from "../HamburgerMenu/HamburgerMenu";
import SideNav from "../SideNav/SideNav";
import styles from "./NavBar.module.css";

interface NavBarProps {
  showNav: boolean;
  windowSize: {
    innerWidth: number;
    innerHeight: number;
  };
}
const NavBar = ({ windowSize, showNav }: NavBarProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const handleHamburgerClick = () => {
    setIsOpen(!isOpen);
  };
  const navigate = useNavigate();

  return (
    <div className={styles.NavBar}>
      <div onClick={() => navigate("/")} className={styles.Logo}>
        🛄 LuggagePilot
      </div>
      {windowSize.innerWidth > 700 ? (
        <>
          <SideNav
            theme={{
              SideNavContainer: styles.SideNav,
              LinkContainer: styles.LinkContainer,
            }}
            showSideNav={showNav}
          />
          <GoogleSignIn />
        </>
      ) : (
        <HamburgerMenu onHandleClick={handleHamburgerClick} isOpen={isOpen} />
      )}
    </div>
  );
};

export default NavBar;
