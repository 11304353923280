import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import DestinationImage from "../../components/ImageContainer/DestinationImage";
import PackingGuide from "../../features/PackingGuide/PackingGuide";
import TripInput from "../../components/TripInput/TripInput";
import { useGetUnsplashImage } from "../../hooks/useGetUnsplashImage";
import { Trip } from "../../types/trip.types";
import styles from "./Trip.module.css";
import { useGetCityWeather } from "../../hooks/useGetCityWeather";
import { useIncrementCounter } from "../../hooks/useIncrementCounter";

export const TripPage = () => {
  const updateCount = useIncrementCounter();

  const location = useLocation();
  const state = location.state;

  const [destination, setDestination] = useState<string>("");
  const [dateRange, setDateRange] = useState<[Date, Date] | undefined>();
  const [completeTrip, setCompleteTrip] = useState<Trip | null>();

  const { data: imageUrl } = useGetUnsplashImage(destination, state?.trip);
  const { data: weatherData, isLoading } = useGetCityWeather(
    destination,
    dateRange,
    !!completeTrip?.temperature,
    completeTrip
  );
  const handleSetCompleteTrip = (trip: Trip) => {
    setCompleteTrip(trip);
  };

  const setDestinationHandler = (input: string) => {
    setDestination(input);
  };

  useEffect(() => {
    if (state?.trip) {
      const trip: Trip = state.trip;
      setDestination(trip?.destination || "");
      setDateRange(
        trip?.startDate && trip?.endDate
          ? [trip.startDate, trip.endDate]
          : undefined
      );
      setCompleteTrip(trip);
    }
  }, [state?.trip]);
  const setDateRangeHandler = (e: [Date, Date]) => {
    setDateRange(e);
  };
  const confirmImage = state?.trip?.photoUrl || imageUrl;
  const displayRecommendation = !!destination && !!dateRange && confirmImage;
  return (
    <div className={styles.Trip}>
      {state?.trip?.photoUrl || imageUrl ? (
        <DestinationImage imgUrl={state?.trip?.photoUrl || imageUrl} />
      ) : null}
      <div className={styles.TripInput}>
        <TripInput
          trip={completeTrip ? completeTrip : undefined}
          isLoading={isLoading}
          destination={destination}
          dateRange={dateRange}
          setDestinationHandler={setDestinationHandler}
          setDateRangeHandler={setDateRangeHandler}
        />
        {displayRecommendation && (
          <PackingGuide
            weatherData={weatherData}
            isLoading={isLoading}
            setCompleteTrip={handleSetCompleteTrip}
            trip={state?.trip}
            imgUrl={imageUrl}
            destination={destination}
            dateRange={dateRange}
          />
        )}
      </div>
    </div>
  );
};
