import { BrowserRouter } from "react-router-dom";
import Router from "./pages/router";
import { supabase } from "./Auth/supabaseClient";
import { QueryClient, QueryClientProvider } from "react-query";
import { useEffect, useState } from "react";

const App = () => {
  const queryClient = new QueryClient({});
  const [session, setSession] = useState<any>(null);

  useEffect(() => {
    supabase.auth.getSession().then(({ data: { session } }) => {
      setSession(session);
    });

    supabase.auth.onAuthStateChange((_event, session) => {
      setSession(session);
    });
  }, []);
  return (
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <Router />
      </BrowserRouter>
    </QueryClientProvider>
  );
};
export default App;
