import { supabase } from "../Auth/supabaseClient";
import { UserPreferences } from "../pages/Preferences";
import { useIsSignedIn } from "./useIsSignedIn";

export const useUpdateUserPreferences = () => {
  const { data: user } = useIsSignedIn();

  return async (prefs: UserPreferences) => {
    const { data, error } = await supabase
      .from("userPrefs")
      .update({
        ...prefs,
        user,
      })
      .eq("user", user);

    if (error) {
      console.log(error);
    }
  };
};
