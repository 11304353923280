import Layout from "../layout/Layout";
import { routerType } from "../types/router.types";
import About from "./About";
import Home from "./Home/Home";
import { Preferences } from "./Preferences/Preferences";
import { TripPage } from "./Trip/TripPage";

const pagesData: routerType[] = [
  {
    path: "",
    element: (
      <Layout>
        <Home />
      </Layout>
    ),
    title: "home",
  },
  {
    path: "trip",
    element: (
      <Layout>
        <TripPage />
      </Layout>
    ),
    title: "trip",
  },
  {
    path: "about",
    element: (
      <Layout>
        <About />
      </Layout>
    ),
    title: "about",
  },
  {
    path: "preferences",
    element: (
      <Layout>
        <Preferences />
      </Layout>
    ),
    title: "preferences",
  },
];

export default pagesData;
