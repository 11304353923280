import React, { useEffect, useState } from "react";
import styles from "./Layout.module.css";
import NavBar from "./NavBar/NavBar";
import SideNav from "./SideNav/SideNav";

interface LayoutProps {
  children: React.ReactNode;
}

function getWindowSize() {
  const { innerWidth, innerHeight } = window;
  return { innerWidth, innerHeight };
}

const Layout = ({ children }: LayoutProps) => {
  const [windowSize, setWindowSize] = useState(getWindowSize());
  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }
    window.addEventListener("resize", handleWindowResize);
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  return (
    <>
      <NavBar windowSize={windowSize} showNav={windowSize.innerWidth < 705} />
      <div className={styles.Layout}>
        <SideNav showSideNav={windowSize.innerWidth > 705} />
        {children}
      </div>
    </>
  );
};

export default Layout;
