import classNames from "classnames";
import styles from "./DestinationImage.module.css";

interface ImageContainerProps {
  className?: string;
  imgUrl: string;
}

const DestinationImage = ({ className, imgUrl }: ImageContainerProps) => {
  return (
    <div className={classNames(styles.DestinationImage, className)}>
      <img className={styles.Image} src={imgUrl} alt="destination" />
    </div>
  );
};

export default DestinationImage;
