import { supabase } from "../Auth/supabaseClient";
import { UserPreferences } from "../pages/Preferences";
import { useIsSignedIn } from "./useIsSignedIn";

export const useSaveUserPreferences = () => {
  const { data: user } = useIsSignedIn();

  return async (prefs: UserPreferences) => {
    const { data, error } = await supabase.from("userPrefs").insert([
      {
        ...prefs,
        user,
      },
    ]);

    if (error) {
      console.log(error);
    }
  };
};
