// FUTURE CONSIDERATIONS

/**
 * Functions take "options" obj that
 * holds user preferences (i.e. I only wear shorts)
 */

// Accessories

export const winterHats = (minTemp: number, preferredTemp = 32) => {
  if (minTemp < preferredTemp) return 1;
  return 0;
};
// Hats should load preferences.
export const hats = (userLikesHats: boolean | null | undefined) => {
  if (userLikesHats) return 1;
  return 0;
};
// Sunglasses should load preferences.
export const sunglasses = (userLikesSunglasses: boolean | null | undefined) => {
  if (userLikesSunglasses) return 1;
};

export const umbrella = (userOwnsUmbrella: boolean | null | undefined) => {
  if (userOwnsUmbrella) {
    return 1;
  }
  return 0;
};

// Clothing
export const heavyCoats = (
  minTemp: number,
  preferredTemp: number | null | undefined
) => {
  if (!preferredTemp) preferredTemp = 32;
  if (minTemp < preferredTemp) return 1;
  return 0;
};
export const jackets = (minTemp: number, preferredTemp = 50) => {
  if (minTemp < preferredTemp) return 1;
  return 0;
};
export const rainJackets = (userOwnsRainJacket: boolean | null | undefined) => {
  if (userOwnsRainJacket) {
    return 1;
  }
  return 0;
};
export const pants = (
  minTemp: number,
  daysTraveling: number,
  preferredTemp: number | null | undefined
) => {
  if (!preferredTemp) {
    preferredTemp = 55;
    if (minTemp < preferredTemp) {
      return Math.ceil(daysTraveling / 7);
    }
  }
  return 0;
};
export const shorts = (
  daysTraveling: number,
  minTemp: number,
  preferredTemp = 55
) => {
  // Default: 4 pair of shorts per week if in warm climate.
  if (minTemp > preferredTemp) {
    return Math.ceil(daysTraveling / 7) * 4;
  }
  // If it's cold ... don't you DARE bring shorts.
  return 0;
};
export const socks = (daysTraveling: number) => {
  return daysTraveling + 1;
};
export const sweaters = (
  daysTraveling: number,
  minTemp: number,
  preferredTemp = 65
) => {
  if (minTemp <= preferredTemp) {
    // pack 1 sweater per week
    return Math.ceil(daysTraveling / 7);
  }
  return 0;
};
export const tShirts = (daysTraveling: number) => {
  // pack t-shirts no matter what.
  const shirtsToPack = Math.ceil(daysTraveling * 0.5);
  // At the very lest, pack 2 shirts. Don't be an animal.
  return Math.max(shirtsToPack, 2);
};

export const underwear = (daysTraveling: number) => {
  return daysTraveling + 1;
};
