import { useQuery } from "react-query";
import { api } from "../api";

export const useIsSignedIn = () => {
  const data = useQuery("isSignedIn", () => api.isSignedIn(), {
    staleTime: 0,
    refetchOnWindowFocus: true,
  });
  return data;
};
