import { useQuery } from "react-query";
import { api } from "../api";
export const useGetUnsplashImage = (
  destination: string,
  imgUrl: string | null | undefined
) => {
  const getCityWeatherQuery = useQuery(
    [destination + "weather"],
    () => api.getPhotoDestination(destination),
    {
      enabled: !!destination.length && !imgUrl,
      staleTime: 1000 * 60 * 60 * 24 * 365,
    }
  );
  return getCityWeatherQuery;
};
