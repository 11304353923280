import { useQuery } from "react-query";
import { api } from "../api";

export const useGetCustomAccessories = (customTripId: number | undefined) => {
  return useQuery(
    [customTripId],
    () => api.getCustomAccessories(customTripId),
    {
      enabled: !!customTripId,
    }
  );
};
