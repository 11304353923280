import { useQuery } from "react-query";
import { api } from "../api";
import { useIsSignedIn } from "./useIsSignedIn";

export const useGetPreferences = () => {
  const { data: loggedIn } = useIsSignedIn();
  return useQuery(["userPreferences"], () => api.getPreferences(loggedIn), {
    enabled: !!loggedIn,
  });
};
