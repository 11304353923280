import styles from "./Home.module.css";
import { useGetAllUserTrips } from "../../hooks/useGetAllUserTrips";
import { Link, useNavigate } from "react-router-dom";
import { useDeleteTripAndCustomItems } from "../../hooks/useDeleteTrip";
import { LoadingSpinner } from "../../components/LoadingSpinner";
import LuggageIcon from "../../svgs/luggage.svg";
import SvgIcon from "../../components/SvgIcon/SvgIcon";
import classNames from "classnames";
import { useIncrementCounter } from "../../hooks/useIncrementCounter";
import { useEffect } from "react";

const Home = () => {
  const { data: allTrips, isLoading } = useGetAllUserTrips();
  const { mutate: deleteTrip } = useDeleteTripAndCustomItems();
  const navigate = useNavigate();

  const count = useIncrementCounter();

  if (isLoading) {
    return (
      <div className={styles.LoadingContainer}>
        <LoadingSpinner className={styles.LoadingSpinner} />;
      </div>
    );
  }

  return (
    <div className={styles.Home}>
      {/* <div
        className="fb-share-button"
        data-href="https://www.your-domain.com/your-page.html"
        data-layout="button_count"
      >
        asdfadsfdsa
      </div>
      <a href="fb-messenger://share/?link= https%3A%2F%2Fdevelopers.facebook.com%2Fdocs%2Fsharing%2Freference%2Fsend-dialog&app_id=123456789">
        Send In Messenger
      </a>
      <a
        target="_blank"
        href="https://twitter.com/intent/tweet?text=LuggagePilot%20helped%20me%20quickly%20generate%20a%20tailored%20packing%20list%20based%20on%20my%20destination's%20weather.%20Check%20it%20out!%0A%0Aluggagepilot.com"
      >
        <TwitterIcon onClick={() => console.log("clicked")} />
      </a> */}
      <div className={styles.TripCardContainer}>
        {allTrips?.map((trip) => {
          let startDate;
          let endDate;
          if (trip.startDate && trip.endDate) {
            startDate = new Date(trip.startDate);
            endDate = new Date(trip.endDate);
          }
          return (
            <div className={styles.TripCard}>
              <div
                onClick={() => {
                  if (trip.tripId) deleteTrip(trip.tripId);
                }}
                className={styles.CloseIcon}
              >
                X
              </div>
              <Link
                className={styles.Link}
                key={trip.destination}
                to={"/trip"}
                state={{ trip }}
              >
                <div className={styles.ImageContainer}>
                  <img
                    src={trip.photoUrl || ""}
                    alt={"trip"}
                    className={styles.Image}
                  />
                </div>
                <div className={styles.TripInfoContainer}>
                  <div className={styles.TripInfoText}>
                    <div className={styles.Descriptor}>Destination:</div>
                    <div className={styles.tripInfo}>{trip.destination}</div>
                  </div>
                  <div className={styles.TripInfoText}>
                    <div className={styles.Descriptor}>Dates:</div>
                    <div className={styles.tripInfo}>
                      {startDate
                        ? startDate.getMonth() +
                          1 +
                          " / " +
                          startDate?.getDate()
                        : null}{" "}
                      -{" "}
                      {endDate
                        ? endDate?.getMonth() + 1 + " / " + endDate?.getDate()
                        : null}
                    </div>
                  </div>
                  <div className={styles.TripInfoText}>
                    <div className={styles.Descriptor}>
                      Temp min / max (°F):
                    </div>
                    <div className={styles.tripInfo}>
                      {trip.temperature?.averageLow}°{" - "}
                      {trip.temperature?.averageHigh}°
                    </div>
                  </div>
                </div>
              </Link>
            </div>
          );
        })}
        <div className={classNames(styles.TripCard, styles.PlanATrip)}>
          <div className={styles.PlanATrip}>
            <SvgIcon width={125} icon={LuggageIcon} />
            <button className={styles.Button} onClick={() => navigate("/trip")}>
              Plan a Trip!
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
