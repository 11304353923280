import { supabase } from "../Auth/supabaseClient";
import { CustomItem } from "../features/PackingGuide/PackingGuide";
import { Trip } from "../types/trip.types";

export const useInsertTrip = () => {
  return async ({
    trip,
    customClothesArray,
    customAccessoryArray,
  }: {
    trip: Trip;
    customClothesArray: CustomItem[] | null;
    customAccessoryArray: CustomItem[] | null;
  }) => {
    const { data: userData } = await supabase.auth.getSession();

    const { data: tripData, error } = await supabase
      .from("Trip")
      .insert([
        {
          photoUrl: trip.photoUrl,
          rainFall: trip.rainFall,
          destination: trip.destination,
          minTemp: trip?.temperature?.averageLow,
          maxTemp: trip?.temperature?.averageHigh,
          startDate: trip?.startDate,
          endDate: trip.endDate,
          user: userData.session?.user.id,
          beanies: trip?.accessories?.beanies,
          sunglasses: trip?.accessories?.sunglasses,
          hats: trip?.accessories?.hats,
          umbrella: trip?.accessories?.umbrella,
          heavyCoats: trip?.clothing?.heavyCoats,
          jackets: trip?.clothing?.jackets,
          rainJackets: trip?.clothing?.rainJackets,
          pants: trip?.clothing?.pants,
          shorts: trip?.clothing?.shorts,
          socks: trip?.clothing?.socks,
          sweaters: trip?.clothing?.sweaters,
          tShirts: trip?.clothing?.tShirts,
          underwear: trip?.clothing?.underwear,
        },
      ])
      .select();
    if (error) console.log(error);
    if (tripData && customClothesArray) {
      const tripId = tripData[0].id;
      customClothesArray.forEach((customItem) => {
        if (!customItem?.TripId) {
          customItem.TripId = tripId;
        }
      });
      const { data, error } = await supabase
        .from("customClothes")
        .insert(customClothesArray)
        .select();
      if (error) console.log(error);
      console.log("custom clothes");
      console.log(data);
    }
    if (tripData && customAccessoryArray) {
      const tripId = tripData[0].id;
      customAccessoryArray.forEach((customItem) => {
        if (!customItem?.TripId) {
          customItem.TripId = tripId;
        }
      });
      const { data, error } = await supabase
        .from("customAccessories")
        .insert(customAccessoryArray)
        .select();
      if (error) console.log(error);
      console.log("custom accessories");
      console.log(data);
    }
  };
};
