import React from "react";
import styles from "./Preferences.module.css";

interface InputFieldProps {
  label: string;
  value: boolean;
  onChange: (value: boolean) => void;
}

export const CheckBox = ({ label, onChange, value }: InputFieldProps) => {
  return (
    <div className={styles.CheckboxContainer}>
      <div className={styles.CheckboxLabel}>{label}</div>
      <div className={styles.InputDiv}>
        <input
          type={"checkbox"}
          checked={value}
          onChange={(e) => onChange(value)}
          className={styles.CheckboxInput}
        />
      </div>
    </div>
  );
};
