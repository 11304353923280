import { useQueryClient } from "react-query";
import { supabase } from "../Auth/supabaseClient";
import { CustomItem } from "../features/PackingGuide/PackingGuide";
import { Trip } from "../types/trip.types";
import { useUpdateCustomClothes } from "./useUpdateCustomClothes";

export const useUpdateTrip = () => {
  const queryClient = useQueryClient();
  const saveNewCustomItem = useUpdateCustomClothes();
  return async ({
    trip,
    id,
    customItemArray,
    customAccessoryArray,
  }: {
    trip: Trip;
    id: number;
    customItemArray: CustomItem[] | null;
    customAccessoryArray: CustomItem[] | null;
  }) => {
    const { data: userData } = await supabase.auth.getSession();
    const { data: tripData, error } = await supabase
      .from("Trip")
      .update({
        photoUrl: trip?.photoUrl,
        destination: trip?.destination,
        minTemp: trip?.temperature?.averageLow,
        maxTemp: trip?.temperature?.averageHigh,
        startDate: trip?.startDate,
        endDate: trip.endDate,
        user: userData.session?.user.id,
        beanies: trip?.accessories?.beanies,
        sunglasses: trip?.accessories?.sunglasses,
        hats: trip?.accessories?.hats,
        umbrella: trip?.accessories?.umbrella,
        heavyCoats: trip?.clothing?.heavyCoats,
        jackets: trip?.clothing?.jackets,
        rainJackets: trip?.clothing?.rainJackets,
        pants: trip?.clothing?.pants,
        shorts: trip?.clothing?.shorts,
        socks: trip?.clothing?.socks,
        sweaters: trip?.clothing?.sweaters,
        tShirts: trip?.clothing?.tShirts,
        underwear: trip?.clothing?.underwear,
      })
      .eq("id", id)
      .select();

    if (error) console.log(error);

    if (tripData && customItemArray) {
      const newItems: CustomItem[] = [];
      customItemArray.forEach((item) => {
        if (!item?.TripId) {
          newItems.push(item);
        } else {
          saveNewCustomItem(item);
        }
      });
      if (newItems.length) {
        newItems.forEach((item) => {
          item.TripId = tripData[0].id;
        });
        const { data, error } = await supabase
          .from("customClothes")
          .insert(newItems)
          .select();
        if (error) {
          console.log(error);
        }
      }
      queryClient.invalidateQueries(["trips"]);
      queryClient.refetchQueries(["trips"]);
      queryClient.fetchQuery(["trips"]);
    }

    if (tripData && customAccessoryArray) {
      const newItems: CustomItem[] = [];
      customAccessoryArray.forEach((item) => {
        if (!item?.TripId) {
          newItems.push(item);
        } else {
          saveNewCustomItem(item);
        }
      });
      if (newItems.length) {
        newItems.forEach((item) => {
          item.TripId = tripData[0].id;
        });
        const { data, error } = await supabase
          .from("customAccessories")
          .insert(newItems)
          .select();
        if (error) {
          console.log(error);
        }
      }
      queryClient.invalidateQueries(["trips"]);
      queryClient.refetchQueries(["trips"]);
      queryClient.fetchQuery(["trips"]);
    }
  };
};
