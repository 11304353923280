import { useQuery } from "react-query";
import { api } from "../api";
import { Trip } from "../types/trip.types";

export const useGetCityWeather = (
  destination: string,
  date: Date[] | undefined,
  hasWeather?: boolean,
  currentTrip?: Trip | null | undefined
) => {
  const getCityWeatherQuery = useQuery(
    [destination + date],
    () => {
      if (date) {
        return api.getCity(destination, date);
      }
    },
    {
      staleTime: 1000000,
    }
  );
  return getCityWeatherQuery;
};
