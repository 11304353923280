import React from "react";
import { useIsSignedIn } from "../../hooks/useIsSignedIn";
import { useSignIn } from "../../hooks/useSignIn";
import { useSignOut } from "../../hooks/useSignOut";

import styles from "./GoogleSignIn.module.css";
const GoogleSignIn = () => {
  const { data } = useIsSignedIn();
  const { mutate: signInWithGoogle } = useSignIn();
  const { mutate: signOut } = useSignOut();

  return data ? (
    <button
      className={styles["login-with-google-btn"]}
      onClick={() => signOut()}
    >
      Sign Out
    </button>
  ) : (
    <button
      className={styles["login-with-google-btn"]}
      onClick={() => signInWithGoogle()}
    >
      Sign In with Google
    </button>
  );
};

export default GoogleSignIn;
