import React, { useEffect, useState } from "react";
import classNames from "classnames";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import styles from "./TripInput.module.css";
import { Trip } from "../../types/trip.types";
import { LoadingSpinner } from "../LoadingSpinner";

interface TripInputProps {
  className?: string;
  destination: string | undefined;
  setDestinationHandler: (e: string) => void;
  dateRange: [Date, Date] | undefined;
  setDateRangeHandler: (e: [Date, Date]) => void;
  trip?: Trip;
  isLoading: boolean;
}

const TripInput = ({
  className,
  destination,
  setDestinationHandler,
  dateRange,
  setDateRangeHandler,
  isLoading,
  trip,
}: TripInputProps) => {
  const [city, setCity] = useState<string>("");

  const inputChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    setCity(e.target.value);
  };

  useEffect(() => {
    if (destination) {
      setCity(destination);
    }
  }, [destination]);

  let startDate;
  let endDate;
  if (trip?.startDate && trip?.endDate) {
    startDate = new Date(trip.startDate);
    endDate = new Date(trip.endDate);
  }
  return (
    <div className={styles.TripInput}>
      <div className={styles.InputAreas}>
        <form
          autoComplete="off"
          className={styles.Form}
          onSubmit={(e) => {
            e.preventDefault();
            setDestinationHandler(city);
          }}
        >
          <input
            name="destination"
            value={city}
            onChange={inputChangeHandler}
            className={styles.DestinationInput}
            placeholder={"Enter Destination ... "}
          />
        </form>
        <div className={styles.FlexBox}>
          {destination && (
            <div className={styles.DatePickerContainer}>
              <div className={styles.DatePickerText}>Travel Dates:</div>
              <DateRangePicker
                rangeDivider={false}
                calendarClassName={styles.DateRangeInput}
                className={styles.DateRangeInputBox}
                value={dateRange || ""}
                onChange={(e: unknown) => {
                  const newDates = e as [Date, Date];
                  setDateRangeHandler(newDates);
                }}
              />
            </div>
          )}

          {dateRange && (
            <div className={styles.DestinationFacts}>
              {isLoading ? (
                <div className={styles.LoadingSpinnerContainer}>
                  <LoadingSpinner
                    width={135}
                    className={styles.LoadingSpinner}
                  />
                </div>
              ) : (
                <>
                  <div className={styles.TripInfoText}>
                    <div className={styles.Descriptor}>Dates:</div>
                    <div className={styles.tripInfo}>
                      {startDate
                        ? startDate.getMonth() +
                          1 +
                          " / " +
                          startDate?.getDate()
                        : null}{" "}
                      -{" "}
                      {endDate
                        ? endDate?.getMonth() + 1 + " / " + endDate?.getDate()
                        : null}
                    </div>
                  </div>
                  <div className={styles.TripInfoText}>
                    <div className={styles.Descriptor}>
                      Temp min / max (°F):
                    </div>
                    <div className={styles.tripInfo}>
                      {trip?.temperature?.averageLow}°{" - "}
                      {trip?.temperature?.averageHigh}°
                    </div>
                  </div>
                  <div className={styles.TripInfoText}>
                    <div className={styles.Descriptor}>
                      Expected Rainfall (inches):
                    </div>
                    <div className={styles.tripInfo}>{trip?.rainFall} </div>
                  </div>
                </>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default TripInput;
