import classNames from "classnames";
import styles from "./LoadingSpinner.module.css";
import loadingSpinner from "../../svgs/loading-spinner.svg";

interface LoadingSpinnerProps {
  className?: string;
  height?: number;
  width?: number;
}

export const LoadingSpinner = ({
  className,
  height,
  width,
}: LoadingSpinnerProps) => {
  return (
    <div className={classNames(className, styles.LoadingSpinner)}>
      <img
        className={styles.img}
        style={{ height, width }}
        src={loadingSpinner}
        alt={"loading"}
      />
    </div>
  );
};
