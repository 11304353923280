import React from "react";
import { InputType } from "./Preferences";
import styles from "./Preferences.module.css";

interface InputFieldProps {
  label: string;
  type: InputType;
  value: number | undefined;
  onChange: (value: number | string) => void;
}

const InputField = ({ label, onChange, value, type }: InputFieldProps) => {
  return (
    <div className={styles.InputContainer}>
      <div className={styles.InputLabel}>{label}</div>
      <div className={styles.InputDiv}>
        <input
          value={value}
          type={type}
          onChange={(e) => onChange(Number(e.target.value))}
          className={styles.Input}
        />
      </div>
    </div>
  );
};

export default InputField;
