import classNames from "classnames";
import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { GetCityResponse } from "../../api";
import { AddCustomPackingItem } from "../../components/AddCustomPackingItem";
import { CustomPackingItem } from "../../components/CustomPackingItem";
import { LoadingSpinner } from "../../components/LoadingSpinner";
import { PackingItem } from "../../components/PackingItem";
import { useGetCustomAccessories } from "../../hooks/useGetCustomAccessories";
import { useGetCustomClothes } from "../../hooks/useGetCustomClothes";
import { useGetPreferences } from "../../hooks/useGetPreferences";
import { useInsertTrip } from "../../hooks/useInsertTrip";
import { useIsSignedIn } from "../../hooks/useIsSignedIn";
import { useSignIn } from "../../hooks/useSignIn";
import { useUpdateTrip } from "../../hooks/useUpdateTrip";
import { Accessories, Clothing, Trip } from "../../types/trip.types";
import {
  hats,
  heavyCoats,
  jackets,
  pants,
  rainJackets,
  shorts,
  socks,
  sunglasses,
  sweaters,
  tShirts,
  umbrella,
  underwear,
  winterHats,
} from "../../utils/trip.utils";
import styles from "./PackingGuide.module.css";

interface PackingGuideProps {
  className?: string;
  destination: string;
  dateRange: [Date, Date];
  imgUrl: string | undefined;
  trip: Trip | undefined;
  weatherData: GetCityResponse | undefined;
  isLoading: boolean;
  setCompleteTrip: (trip: Trip) => void;
}

export interface CustomItem {
  id?: number;
  clothesName: string;
  clothesNumber: number;
  TripId?: number;
}

const PackingGuide = ({
  setCompleteTrip,
  className,
  destination,
  dateRange,
  weatherData,
  isLoading,
  imgUrl,
  trip,
}: PackingGuideProps) => {
  // This component takes destination and dateRange.
  const saveTrip = useInsertTrip();
  const updateTrip = useUpdateTrip();
  const { data: customItemsFromBackend } = useGetCustomClothes(trip?.tripId);
  const { data: customAccessoriesFromBackend } = useGetCustomAccessories(
    trip?.tripId
  );
  const { mutate: signInWithGoogle } = useSignIn();
  const { data: isSignedIn } = useIsSignedIn();
  const { data: preferences } = useGetPreferences();
  const [customClothingItem, setCustomClothingItem] = useState<
    CustomItem[] | null
  >();
  const [customAccessoryItem, setCustomAccessoryItem] = useState<
    CustomItem[] | null
  >();

  const handleAdjustCustomClothingItem = (num: number, item: CustomItem) => {
    // If itemNumber is at 0 and num is negative one, just return.
    if (item.clothesNumber === 0 && num === -1) return;
    if (!customClothingItem || !customClothingItem.length) return;
    const copy = [...customClothingItem];
    copy.forEach((obj) => {
      if (obj.clothesName === item.clothesName) {
        obj.clothesNumber = obj.clothesNumber + num;
      }
    });
    setCustomClothingItem(copy);
  };

  const handleAdjustCustomAccessoryItem = (num: number, item: CustomItem) => {
    // If itemNumber is at 0 and num is negative one, just return.
    if (item.clothesNumber === 0 && num === -1) return;
    if (!customAccessoryItem || !customAccessoryItem.length) return;
    const copy = [...customAccessoryItem];
    copy.forEach((obj) => {
      if (obj.clothesName === item.clothesName) {
        obj.clothesNumber = obj.clothesNumber + num;
      }
    });
    setCustomAccessoryItem(copy);
  };

  const handleCustomAccessoryItem = (item: CustomItem) => {
    if (customAccessoryItem) {
      if (
        customAccessoryItem.find(
          (existingItem) =>
            existingItem.clothesName.toLowerCase() ===
            item.clothesName.toLowerCase()
        )
      ) {
        return;
      }
      setCustomAccessoryItem([...customAccessoryItem, item]);
    } else {
      setCustomAccessoryItem([item]);
    }
  };
  const handleCustomClothingItem = (item: CustomItem) => {
    if (customClothingItem) {
      if (
        customClothingItem.find(
          (existingItem) =>
            existingItem.clothesName.toLowerCase() ===
            item.clothesName.toLowerCase()
        )
      ) {
        return;
      }
      setCustomClothingItem([...customClothingItem, item]);
    } else {
      setCustomClothingItem([item]);
    }
  };

  const navigate = useNavigate();

  const differenceInTime = dateRange[1].getTime() - dateRange[0].getTime();
  const differenceInDays = differenceInTime / (1000 * 3600 * 24);
  const daysTraveling = Math.round(differenceInDays);

  const tripBuilder = useMemo(() => {
    if (trip) {
      return trip;
    }
    if (weatherData) {
      let minTemp = Infinity;
      let maxTemp = 0;
      let rainFall = 0;
      let totalHumidity = 0;
      let count = 0;
      let avgHumidity = 0;

      weatherData?.hourly.precipitation.forEach((r) => (rainFall += r));
      rainFall = Math.floor(rainFall);

      // Data comes as two large arrays of low/high temps per each day
      // Loop through and store highest/lowest temps
      weatherData?.hourly?.temperature_2m?.forEach((temp) => {
        minTemp = Math.min(minTemp, temp);
        maxTemp = Math.max(maxTemp, temp);
      });
      weatherData?.hourly?.temperature_2m?.forEach((temp) => {
        minTemp = Math.min(minTemp, temp);
        maxTemp = Math.max(maxTemp, temp);
      });

      weatherData?.hourly.relativehumidity_2m.forEach((humidityItem) => {
        totalHumidity += humidityItem;
        count = count + 1;
      });

      avgHumidity = totalHumidity / count;

      const packingList: Trip = {
        photoUrl: imgUrl,
        destination,
        startDate: dateRange[0],
        endDate: dateRange[1],
        rainFall,
        temperature: {
          averageHigh: maxTemp,
          averageLow: minTemp,
        },
        accessories: {
          beanies: winterHats(minTemp),
          sunglasses: sunglasses(preferences?.likesSunglasses) ? 1 : 0,
          hats: hats(preferences?.likesHats), // Switch with preferences
          umbrella: umbrella(preferences?.umbrella) ? 1 : 0,
        },
        clothing: {
          heavyCoats: heavyCoats(minTemp, preferences?.heavyCoats),
          jackets: jackets(minTemp),
          rainJackets: rainJackets(preferences?.ownsRainJacket),
          pants: pants(minTemp, daysTraveling, preferences?.pants),
          shorts: shorts(daysTraveling, minTemp),
          socks: socks(daysTraveling),
          sweaters: sweaters(daysTraveling, minTemp),
          tShirts: tShirts(daysTraveling),
          underwear: underwear(daysTraveling),
        },
      };
      return packingList;
    }
    return null;
  }, [
    trip,
    dateRange,
    imgUrl,
    destination,
    weatherData,
    daysTraveling,
    preferences,
  ]);

  const [accessoryList, setAccessoryList] = useState<null | Accessories>(null);
  const [clothingList, setClothingList] = useState<null | Clothing>(null);
  useEffect(() => {
    if (tripBuilder) {
      setAccessoryList(tripBuilder.accessories);
      setClothingList(tripBuilder.clothing);
      setCompleteTrip(tripBuilder);
    }

    if (customItemsFromBackend) {
      const customClothes = customItemsFromBackend as CustomItem[];
      setCustomClothingItem(customClothes);
    }

    if (customAccessoriesFromBackend) {
      const customAccessories = customAccessoriesFromBackend as CustomItem[];
      setCustomAccessoryItem(customAccessories);
    }
  }, [
    tripBuilder,
    setCompleteTrip,
    customItemsFromBackend,
    customAccessoriesFromBackend,
  ]);

  const handleAccessoryChange = (key: keyof Accessories, amount: number) => {
    if (accessoryList) {
      setAccessoryList({ ...accessoryList, [`${key}`]: amount });
    }
  };

  const handleClothingChange = (key: keyof Clothing, amount: number) => {
    if (clothingList) {
      setClothingList({ ...clothingList, [`${key}`]: amount });
    }
  };

  return (
    <>
      <div className={styles.PackingGuideContainer}>
        <div className={classNames(styles.PackingGuide, className)}>
          <div className={styles.PackContainer}>
            {isLoading && !tripBuilder?.temperature ? (
              <LoadingSpinner width={135} />
            ) : (
              <>
                <h2>Clothes</h2>
                <div className={styles.Clothing}>
                  {clothingList && (
                    <PackingItem
                      onChange={handleClothingChange}
                      packGroup={clothingList}
                    />
                  )}
                  {customClothingItem && (
                    <CustomPackingItem
                      customItems={customClothingItem}
                      onChange={handleAdjustCustomClothingItem}
                    />
                  )}
                  <AddCustomPackingItem
                    setCustomItem={handleCustomClothingItem}
                  />
                </div>
              </>
            )}
          </div>
          <div className={styles.PackContainer}>
            {isLoading && !tripBuilder?.temperature ? (
              <LoadingSpinner width={135} />
            ) : (
              <>
                <h2>Accessories</h2>
                <div className={styles.Clothing}>
                  {accessoryList && (
                    <PackingItem
                      onChange={handleAccessoryChange}
                      packGroup={accessoryList}
                    />
                  )}
                  {customAccessoryItem && (
                    <CustomPackingItem
                      customItems={customAccessoryItem}
                      onChange={handleAdjustCustomAccessoryItem}
                    />
                  )}
                  <AddCustomPackingItem
                    setCustomItem={handleCustomAccessoryItem}
                  />
                </div>
              </>
            )}
          </div>
        </div>
        <div className={styles.ButtonContainer}></div>
        {isSignedIn ? (
          <button
            className={styles.Button}
            onClick={() => {
              if (tripBuilder) {
                trip?.tripId
                  ? updateTrip({
                      trip: {
                        ...trip,
                        clothing: clothingList,
                        accessories: accessoryList,
                      },
                      id: trip.tripId,
                      customItemArray: customClothingItem
                        ? customClothingItem
                        : null,
                      customAccessoryArray: customAccessoryItem
                        ? customAccessoryItem
                        : null,
                    })
                  : saveTrip({
                      trip: tripBuilder,
                      customClothesArray: customClothingItem
                        ? customClothingItem
                        : null,
                      customAccessoryArray: customAccessoryItem
                        ? customAccessoryItem
                        : null,
                    });
              }
              navigate("/");
            }}
          >
            {trip?.tripId ? "Update Trip" : "Save Trip"}
          </button>
        ) : (
          <button className={styles.Button} onClick={() => signInWithGoogle()}>
            Sign in to Save
          </button>
        )}
      </div>
    </>
  );
};

export default PackingGuide;
