import axios from "axios";
import { supabase } from "./Auth/supabaseClient";

export interface GetCityResponse {
  hourly: {
    precipitation: number[];
    temperature_2m: number[];
    relativehumidity_2m: number[];
  };
}

export const api = {
  incrementCount: async () => {
    return await axios.get("https://luggage-server.onrender.com/count");
  },
  deleteTripAndCustomItems: async (tripId: number) => {
    const { data: customItems, error: customItemsError } = await supabase
      .from("customClothes")
      .delete()
      .eq("TripId", tripId);
    const { data: customAccessories, error: customAccessoriesError } =
      await supabase.from("customAccessories").delete().eq("TripId", tripId);
    console.log(customAccessoriesError);

    const { data: tripData, error } = await supabase
      .from("Trip")
      .delete()
      .eq("id", tripId);
    console.log(error);
    return {
      customClothes: customItems,
      customAccessories: customAccessories,
      trip: tripData,
    };
  },
  getPreferences: async (userId: string | null | undefined) => {
    const { data: prefs } = await supabase
      .from("userPrefs")
      .select("*")
      .eq("user", userId)
      .single();
    return prefs;
  },
  getCustomClothes: async (tripId: number | undefined) => {
    if (!tripId) return;
    const { data: customItems, error } = await supabase
      .from("customClothes")
      .select("*")
      .eq("TripId", tripId);
    if (error) {
      console.log(error);
    }
    return customItems;
  },
  getCustomAccessories: async (tripId: number | undefined) => {
    if (!tripId) return;
    const { data: customItems, error } = await supabase
      .from("customAccessories")
      .select("*")
      .eq("TripId", tripId);
    if (error) {
      console.log(error);
    }
    return customItems;
  },
  isSignedIn: async () => {
    const { data: sessionData } = await supabase.auth.getSession();
    if (sessionData.session) {
      localStorage.setItem("token123321", sessionData.session.user.id);
    }
    return localStorage.getItem("token123321");
  },
  signIn: async () => {
    await supabase.auth
      .signInWithOAuth({
        provider: "google",
      })
      .then(() => {});
  },
  signOut: async () => {
    await supabase.auth.signOut();
    const item = localStorage.removeItem("token123321");
    return item;
  },
  getAllTrips: async () => {
    const { data } = await supabase.auth.getSession();
    const userId = data.session?.user.id;
    let { data: response, error } = await supabase
      .from("Trip")
      .select("*")
      .eq("user", userId);
    return response;
  },
  getSingleTrip: async (tripId: string) => {
    let { data, error } = await supabase
      .from("Trip")
      .select("*")
      .eq("id", tripId)
      .single();
    return data;
  },
  getPhotoDestination: async (destination: string) => {
    const { data: response } = await axios.get(
      "https://luggage-server.onrender.com/image",
      {
        params: {
          destination,
        },
      }
    );
    return response;
  },
  getCity: async (destination: string, date: Date[]) => {
    const { data: response } = await axios.get<GetCityResponse>(
      "https://luggage-server.onrender.com/city",
      {
        params: {
          destination,
          date,
        },
      }
    );
    return response;
  },
};
