import { useState } from "react";
import { CustomItem } from "../../features/PackingGuide/PackingGuide";
import styles from "./CustomPackingItem.module.css";

interface PackingItemProps {
  tripId?: number;
  setCustomItem: (item: CustomItem) => void;
}

export const AddCustomPackingItem = ({
  setCustomItem,
  tripId,
}: PackingItemProps) => {
  const [active, setActive] = useState(false);
  const [inputText, setInputText] = useState("");
  const onClickHandler = () => {
    setActive(!active);
  };
  const saveItem = (item: string) => {
    setCustomItem({
      clothesName: item,
      clothesNumber: 0,
      TripId: tripId,
    });
    setInputText("");
    setActive(!active);
  };

  const render = !active ? (
    <div onClick={onClickHandler} className={styles.PackingItem}>
      <div className={styles.Label}>Add Custom Item</div>
      <div className={styles.PlusMinusPLUS}>+</div>
    </div>
  ) : (
    <div className={styles.PackingItemActive}>
      <div className={styles.Label}>
        <input
          onChange={(e) => setInputText(e.target.value)}
          value={inputText}
          className={styles.CustomInput}
        />
      </div>
      <div onClick={() => saveItem(inputText)} className={styles.PlusMinus}>
        add
      </div>
      <div onClick={onClickHandler} className={styles.PlusNope}>
        cancel
      </div>
    </div>
  );
  return render;
};
