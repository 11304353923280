import { CustomItem } from "../../features/PackingGuide/PackingGuide";
import styles from "./CustomPackingItem.module.css";

interface CustomPackingItemProps {
  customItems: CustomItem[];
  onChange: (num: number, item: CustomItem) => void;
}

export const CustomPackingItem = ({
  customItems,
  onChange,
}: CustomPackingItemProps) => {
  return (
    <>
      {customItems.map((item) => {
        return (
          <div key={item.clothesName} className={styles.PackingItem}>
            <div className={styles.Label}>{item.clothesName}:</div>
            <div className={styles.PlusMinusContainer}>
              <div className={styles.Value}>{item.clothesNumber}</div>
              <div
                onClick={() => onChange(-1, item)}
                className={styles.PlusMinus}
              >
                -
              </div>
              <div
                onClick={() => onChange(1, item)}
                className={styles.PlusMinus}
              >
                +
              </div>
            </div>
          </div>
        );
      })}
    </>
  );
};
