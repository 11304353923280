import { Accessories, Clothing } from "../../types/trip.types";
import styles from "./PackingItem.module.css";

interface PackingItemProps {
  packGroup: Accessories | Clothing;
  onChange: (key: keyof Clothing | keyof Accessories, amount: number) => void;
}

export const PackingItem = ({ packGroup, onChange }: PackingItemProps) => {
  const handleNumChange = (
    item: keyof Clothing | keyof Accessories,
    number: number
  ) => {
    const packingItem = packGroup[item];
    if (!packingItem && packingItem !== 0) return;

    if (packingItem === 0 && number < 0) return;
    onChange(item, packingItem + number);
  };
  const items = Object.keys(packGroup).map((clothingItem) => {
    return (
      <div key={clothingItem} className={styles.PackingItem}>
        <div className={styles.Label}>{clothingItem}:</div>
        <div className={styles.PlusMinusContainer}>
          <div className={styles.Value}>{packGroup[clothingItem]}</div>
          <div
            onClick={() => handleNumChange(clothingItem, -1)}
            className={styles.PlusMinus}
          >
            -
          </div>
          <div
            onClick={() => handleNumChange(clothingItem, 1)}
            className={styles.PlusMinus}
          >
            +
          </div>
        </div>
      </div>
    );
  });

  return <>{items}</>;
};
