import { supabase } from "../Auth/supabaseClient";
import { CustomItem } from "../features/PackingGuide/PackingGuide";

export const useUpdateCustomClothes = () => {
  return async (customItem: CustomItem) => {
    const { data, error } = await supabase
      .from("customClothes")
      .update(customItem)
      .eq("id", customItem?.id);
    if (error) console.log(error);
    return data;
  };
};
