import { Link } from "react-router-dom";
import GoogleSignIn from "../../features/GoogleSignIn/GoogleSignIn";
import { useIsSignedIn } from "../../hooks/useIsSignedIn";
import styles from "./HamburgerMenu.module.css";

interface HamburgerMenuProps {
  isOpen: boolean;
  onHandleClick: () => void;
}

export const HamburgerMenu = ({
  isOpen,
  onHandleClick,
}: HamburgerMenuProps) => {
  const { data: isSignedIn } = useIsSignedIn();
  return (
    <>
      <div onClick={() => onHandleClick()} className={styles.HamburgerMenu}>
        <div className={styles.SvgHolder}>
          <svg viewBox="0 0 100 80" width="40" height="40">
            <rect width="100" height="20"></rect>
            <rect y="30" width="100" height="20"></rect>
            <rect y="60" width="100" height="20"></rect>
          </svg>
        </div>

        {isOpen && (
          <div className={styles.BurgerMenu}>
            <Link className={styles.Link} to="/">
              Home
            </Link>

            {isSignedIn && (
              <Link className={styles.Link} to="/preferences">
                Preferences
              </Link>
            )}

            <Link className={styles.Link} to="/trip">
              New Trip
            </Link>
            <GoogleSignIn />
          </div>
        )}
      </div>
    </>
  );
};
