import React from "react";
import classNames from "classnames";
import styles from "./SideNav.module.css";
import { Link } from "react-router-dom";
import { useIsSignedIn } from "../../hooks/useIsSignedIn";

interface Theme {
  SideNavContainer?: string;
  LinkContainer?: string;
}

interface SideNavProps {
  className?: string;
  showSideNav: boolean;
  theme?: Theme;
}

const SideNav = ({ className, showSideNav, theme }: SideNavProps) => {
  const { data: isSignedIn } = useIsSignedIn();
  return showSideNav ? (
    <div
      className={classNames(className, styles.SideNav, theme?.SideNavContainer)}
    >
      <ul className={classNames(styles.LinkContainer, theme?.LinkContainer)}>
        {/* <li>
          <Link className={styles.Link} to="/">
            Home
          </Link>
        </li> */}
        {isSignedIn && (
          <li>
            <Link className={styles.Link} to="/preferences">
              Preferences
            </Link>
          </li>
        )}
        <li>
          <Link className={styles.Link} to="/trip">
            New Trip
          </Link>
        </li>
        {/* <li>
          <Link className={styles.Link} to="/about">
            About
          </Link>
        </li> */}
        {/* <li>
          <Link className={styles.Link} to="/">
            Login
          </Link>
        </li> */}
      </ul>
    </div>
  ) : null;
};

export default SideNav;
