import { useMutation, useQueryClient } from "react-query";
import { api } from "../api";
export const useDeleteTripAndCustomItems = () => {
  const queryClient = useQueryClient();
  return useMutation(
    async (tripId: number) => {
      return await api.deleteTripAndCustomItems(tripId);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["trips"]);
        queryClient.refetchQueries(["trips"]);
        queryClient.fetchQuery(["trips"]);
      },
    }
  );
};
